import { Button, Table } from 'semantic-ui-react'
import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";

import CMP_PAGINATION from "../cmp_pagination/cmp_pagination";

import './cmp_history.css';
import Moment from "moment";



export default function CMP_HISTORY({ set_mdl_open, var_modaltitle, var_target_id }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const [var_history, set_history] = useState([]);
    const [var_ready, set_ready] = useState(false);
    const [var_loading, set_loading] = useState([]);
    const [var_limit, set_limit] = useState(10);
    const [var_offset, set_offset] = useState(0);
    const [var_sortby, set_sortby] = useState('epochtransactiondate');
    const [var_sortorder, set_sortorder] = useState('descending');



    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        populate_history()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_history(limit, offset, sortby, sortorder) {

        set_loading(true);

        if (limit) {
            set_limit(limit);
        } else {
            limit = var_limit
        }

        if (offset || offset === 0) {
            set_offset(offset);
        } else {
            offset = var_offset
        }

        if (sortby) {
            set_sortby(sortby);
        } else {
            sortby = var_sortby
        }

        if (sortorder) {
            set_sortorder(sortorder);
        } else {
            sortorder = var_sortorder
        }

        await API_get_history(limit, offset, sortby, sortorder);

        set_loading(false);
        set_ready(true);

    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_history(limit, offset, sortby, sortorder) {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        return API.get('translation', '/get-trans-history/' + var_target_id[0] + '/' + var_target_id[1] + '?limit=' + limit + '&offset=' + offset + '&sortby=' + sortby + '&sortorder=' + sortorder)
            .then((transactions) => {
                set_history(transactions);
            });
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_sort(sortby) {
        var sortorder = var_sortorder;
        if (var_sortby !== sortby) {
            sortorder = 'ascending';
        } else {
            if (sortorder === 'ascending') {
                sortorder = 'descending';
            } else if (sortorder === 'descending') {
                sortorder = 'ascending';
            }
        }
        set_sortorder(sortorder);
        populate_history('', 0, sortby, sortorder)
    }

    function onClick_mdl_history_close() {
        set_mdl_open(false);
    }

    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="modal-header">
                <div className="modal-header-title">History: {var_modaltitle}</div>
                <div className="modal-header-close" onClick={() => set_mdl_open(false)}>
                    <img src="/icons/x 60px (717473).svg" alt="Modal close" />
                </div>
            </div>
            <div className="modal-content">
                <div className="content-filter">
                    <CMP_PAGINATION var_limit={var_limit.toString()} var_ready={var_ready} var_loading={var_loading} tabledata={var_history} populatefunction={populate_history}></CMP_PAGINATION>
                </div>
                <div className="tablewrapper">
                    <Table sortable compact className={(var_loading ? " tbl_loading" : "")}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell sorted={var_sortby === 'description' ? var_sortorder : null} onClick={() => onClick_sort('description')}>DESCRIPTION</Table.HeaderCell>
                                <Table.HeaderCell sorted={var_sortby === 'epochtransactiondate' ? var_sortorder : null} onClick={() => onClick_sort('epochtransactiondate')}>TRANSACTION DATE</Table.HeaderCell>
                                <Table.HeaderCell sorted={var_sortby === 'transactionby' ? var_sortorder : null} onClick={() => onClick_sort('transactionby')}>TRANSACTION BY</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        {var_ready && var_history[0]['totalrows'] !== 0 &&
                            <Table.Body>
                                {var_history.map((item, i) =>
                                    <Table.Row key={i}>
                                        <Table.Cell>{item.description}</Table.Cell>
                                        <Table.Cell>{(item.epochtransactiondate !== undefined && item.epochtransactiondate !== null && item.epochtransactiondate !== "" ? Moment.unix(item.epochtransactiondate).format('lll') : "n/a")}</Table.Cell>
                                        <Table.Cell>{item.transactionby}</Table.Cell>
                                    </Table.Row>
                                )}
                            </Table.Body>
                        }
                    </Table>
                    {var_ready && var_history[0]['totalrows'] === 0 &&
                        <div className="emptytable"><img src="/icons/alert 60px (e5e5e5).svg" alt='alert icon' />there are no history transactions to display</div>
                    }
                </div>
            </div>
            <div className="modal-footer">
                <div className="modal-footer-buttons">
                    <Button className="btn_primary btn_active" onClick={() => onClick_mdl_history_close()}>CLOSE</Button>
                </div>
            </div>
        </>
    )
}