import React from "react";
import { Route, Switch } from "react-router-dom";
import AppliedRoute from "./applied_route";

import ENTRIES from './containers/entries/entries';
import LOGIN from './containers/login/login';
import MISSING from './containers/missing/missing';
import DUPLICATES from './containers/duplicates/duplicates';
import PENDING from './containers/pending/pending';
import ACCESSDENIED from './containers/accessdenied/accessdenied';
import NOTFOUND from './containers/notfound/notfound';

export default function Routes({ appProps }) {
    return (
        <Switch>
            <AppliedRoute exact path="/" component={ENTRIES} appProps={appProps} />
            <AppliedRoute path="/login" exact component={LOGIN} appProps={appProps} />
            <AppliedRoute exact path="/missing" component={MISSING} appProps={appProps} />
            <AppliedRoute exact path="/duplicates" component={DUPLICATES} appProps={appProps} />
            <AppliedRoute exact path="/pending" component={PENDING} appProps={appProps} />
            <AppliedRoute exact path="/access-denied" component={ACCESSDENIED} appProps={appProps} />
            <Route component={NOTFOUND} />
        </Switch>
    );
}
