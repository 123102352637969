const config = {
    apiGateway_admin: {
        REGION: "ca-central-1",
        URL: "https://api.credivera.com/translation"
    },
    apiGateway_translation: {
        REGION: "ca-central-1",
        URL: "https://api.credivera.com/translation"
    },
    cognito: {
        REGION: "us-east-1",
        USER_POOL_ID: "us-east-1_7pkPzUpQ5",
        APP_CLIENT_ID: "a0r4qbd3hjs818beu4uep40qb",
        IDENTITY_POOL_ID: "us-east-1:ff1b864d-2496-4394-aa0c-6635da9cbd2f"
    }
};
export default config;
