import { API, Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { Button, Form, Input } from 'semantic-ui-react'
import { useState } from "react";
import React from "react";

import "./login.css";



export default function LOGIN(appProps) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const history = useHistory();

    const [ var_login, set_login ] = useState([]);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_error, set_error ] = useState(false);
    const [ var_message, set_message ] = useState('');



    //  event listeners ------------------------------------------------------------------------------------------------



    //  async functions ------------------------------------------------------------------------------------------------

    async function login() {
        set_error(false);
        set_processing(true);

        //  check cognito authentication
        await Auth.signIn(var_login.email, var_login.password)
            .then(async (var_AWSauth) => {
                if (var_AWSauth.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    set_error(true);
                    set_processing(false);
                    set_message('New password is required.');
                    sessionStorage.clear();
                } else {
                    let AWSuser = await Auth.currentUserInfo();

                    //  get TerraHub user details
                    await API_get_authentication(AWSuser.username)
                        .then(async (authentication) => {
                            if (['F003563C-2E07-4C33-8EED-EBC91DF66E68', // PROD - Elena Dumitrascu (elena+th@terrahub.ca)
                                 '720370FF-1F91-444F-9105-20816DFF6243', // PROD - Andy Jay (andy@terrahub.ca)
                                 'C03BE106-C2FD-458E-B983-79F87B81F83C', // PROD - Derek Johnson (derekj@studiosparqs.com)
                                 '91F47469-7FF2-4CEC-A679-7803F6D88BAE', // PROD - Shawn Fenemore (srfenemore+terra@gmail.com)
                                 '0FE6992E-F969-4B7B-AC79-20D341E38AA6', // PROD - Mirela Balan ('mirelabalan03+newprodthsa@gmail.com)
                                 'B76E1240-520C-4D9E-AA4A-35BAA964FBD6', // QA - DanT1 BalanT1 (contactdbalan+t1@gmail.com)
                                 '2C4F8362-A3C9-4897-9116-9B697E0EC877', // QA - Stephen Rosinski (stephen@credivera.com)
                                 'CC4AD064-8F1E-4552-BABB-81115309E794', // QA - Greg Ma (greg@credivera.com)
                                 'B42FACC3-4C77-4050-AE60-1B06BE6E874E', // QA - Derek Johnson (derekj@studiosparqs.com)
                                 '83549DBB-8CB6-4C2C-AB30-240315BCBBCF', // QA - Andy Jay (andy@terrahub.ca)
                                 '1D60A719-107B-4BD7-BBAD-80FD886A81D4'  // QA - TS Balan (mirelabalan03+ts@gmail.com)
                                ].includes(authentication.id)) {
                                sessionStorage.setItem('authentication', JSON.stringify(authentication));
                                appProps.onChange_authentication(true);
                                history.push("/");
                            } else {
                                await Auth.signOut()
                                    .then(() => {
                                        sessionStorage.clear();
                                    })
                                    .then(() => {
                                        appProps.onChange_authentication(false);
                                        history.push("/access-denied");
                                    });
                            }
                        })
                        .catch((e) => {
                            console.log("Error::API_get_authentication", e);
                            set_error(true);
                            set_processing(false);
                        });
                }
            })
            .catch((e) => {
                console.log("Error::OnSingIn", e);
                set_error(true);
                set_processing(false);
            });
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_authentication(cognito_id) {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        return API.get('admin', '/get-authentication/' + cognito_id)
            .then((result) => {
                console.log("result::",result);
            let authentication = {};
            authentication['authenticated'] = true;
            authentication['id'] = result.id;
            authentication['cognito_id'] = cognito_id;
            authentication['permission_id'] = result.permission_id;
            authentication['firstname'] = result.firstname;
            authentication['lastname'] = result.lastname;
            authentication['email'] = result.email;
            return authentication;
        })
        .catch((e) => {
            console.log("Error::API_get_authentication", e);
            set_error(true);
            set_processing(false);
        });
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_input(event) {
        if (event.target.name === 'email') {
            event.target.value = event.target.value.toLowerCase().trim()
        }
        if (event.target.name === 'password') {
            event.target.value = event.target.value.trim()
        }
        const login = { ...var_login };
        login[event.target.name] = event.target.value;
        set_login(login);
        if (login.email !== undefined && login.email !== null && login.email !== "" &&
            login.password !== undefined && login.password !== null && login.password !== "") {
            set_ready(true);
            set_error(false);
        }
    }

    // RENDER APP ======================================================================================================

    return(

        <div className="login_wrapper">
            <div className="login">
                <Form onSubmit={() => var_ready ? login() : false}>
                    <Form.Group>
                        <Form.Field
                            required
                            className='email'
                            id='email'
                            name='email'
                            value={var_login.email || ''}
                            control={Input}
                            onChange={onChange_input}
                            label='EMAIL'
                            placeholder='Email...'
                            fluid
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Field
                            required
                            className='password'
                            id='password'
                            name='password'
                            autoComplete='new-password'
                            value={var_login.password || ''}
                            control={Input}
                            type="password"
                            onChange={onChange_input}
                            label='PASSWORD'
                            placeholder='Password...'
                        />
                    </Form.Group>

                    {var_error &&
                        <div id="loginerror" className="message warning">
                            <div className="message_icon"><img src="/icons/warning 60px (d91e18).svg" alt='warning icon' /></div>
                            {var_message === '' ?
                                <div className="message_text_wrapper">
                                    <div className="message_text cause">We were unable to log you in.</div>
                                    <div className="message_text resolution">'Please check your email and password and try again.'</div>
                                </div>
                                :
                                <div className="message_text_wrapper">{var_message}</div>
                            }
                        </div>
                    }

                    {!var_processing ?
                        <Button type='submit' id="btn_login" className={"btn_primary " + (var_ready ? 'btn_active' : '')}>LOGIN</Button>
                        :
                        <Button loading id="btn_login" className="btn_primary btn_active">LOGIN</Button>
                    }
                </Form>
            </div>
        </div>
    )


};
