import React from 'react';
import { Button } from 'semantic-ui-react'

import './cmp_session_expired.css'

export default function CMP_SESSION_EXPIRED({ handleLogout }) {

    //  variable declarations ------------------------------------------------------------------------------------------


    //  event listeners ------------------------------------------------------------------------------------------------


    //  async functions ------------------------------------------------------------------------------------------------


    //  API calls ------------------------------------------------------------------------------------------------------


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_mdl_session_expired() {
        handleLogout();
    }

    // RENDER APP ======================================================================================================


    return (
        <>
            <div className="modal-header">
                <div className="modal-header-title">Session expired</div>
            </div>
            <div className="modal-content">
                Please login again
            </div>
            <div className="modal-footer">
                <div className="modal-footer-buttons">
                    <Button onClick={() => onClick_mdl_session_expired()} className="btn_primary btn_active">LOGIN</Button>
                </div>
            </div>
        </>
    )
}
