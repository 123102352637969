import React, { useState, useEffect } from 'react';
import Routes from "./routes";
import { useHistory } from "react-router-dom";
import { Auth, API } from "aws-amplify";
import { Modal } from 'semantic-ui-react';

import HEADER from './components/cmp_header/cmp_header';
import SIDEBAR from './components/cmp_sidebar/cmp_sidebar';
import CMP_SESSION_EXPIRED from './components/cmp_session_expired/cmp_session_expired';

import 'semantic-ui-css/semantic.min.css'
import './App.css'

function App() {

    //  variable declarations ------------------------------------------------------------------------------------------
    const history = useHistory();

    const [var_isAuthenticated, set_isAuthenticated] = useState(sessionStorage.getItem('authentication') ? JSON.parse(sessionStorage.getItem('authentication')).authenticated : false);

    const [var_locales, set_locales] = useState([]);
    const [var_entries, set_entries] = useState(0);
    const [var_duplicates, set_duplicates] = useState(0);
    const [var_missing, set_missing] = useState(0);
    const [var_pending, set_pending] = useState(0);
    const [var_sidebar_loading, set_sidebar_loading] = useState(true);
    const [var_session_expired_time, set_session_expired_time] = useState();
    const [var_mdl_session_expired_open, set_mdl_session_expired_open] = useState(false);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (sessionStorage.getItem('authentication')) {
            Auth.currentAuthenticatedUser()
                .then((user) => {
                    populate_sidebar();
                    set_session_expired_time((user.signInUserSession.idToken.payload.exp - user.signInUserSession.idToken.payload.auth_time) * 1000);
                })
                .catch(() => {
                    console.log("Session Expired");
                    set_mdl_session_expired_open(true);
                });
        }
    }, [var_isAuthenticated]);

    useEffect(() => {
        if (var_session_expired_time !== undefined && var_session_expired_time !== null && sessionStorage.getItem('authentication')) {
            setTimeout(() => {
                console.log("Session Expired");
                set_mdl_session_expired_open(true);
            }, var_session_expired_time);
        }
    }, [var_session_expired_time]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_sidebar() {
        set_sidebar_loading(true);

        await API_get_translations_sta()
        .then((stats)=>{
            set_locales(stats.locales.split(","));
            set_entries(stats.totalrows);
            set_missing(stats.missing);
            set_duplicates(stats.duplicates);
            set_pending(stats.pendings);
        }).then(()=>{
            set_sidebar_loading(false);
        });
    }

    async function handleLogout() {
        await Auth.signOut()
            .then(() => {
                sessionStorage.clear();
            })
            .then(() => {
                set_isAuthenticated(false);
                set_mdl_session_expired_open(false);
                history.push("/login");
            });
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_translations_sta() {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        return API.get('translation', `/get-translations-sta`);
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_updateStats(){
        populate_sidebar();
    }

    function onChange_authentication(isAuthenticated) {
        set_isAuthenticated(isAuthenticated);
    }


    // RENDER APP ======================================================================================================


    return (
        <>
            { var_isAuthenticated ?
                <div className="app_container">
                    <HEADER appProps={{ var_isAuthenticated, handleLogout }} />
                    <div className="app_content">
                        <SIDEBAR appProps={{ var_sidebar_loading, var_locales, var_entries, var_duplicates, var_missing, var_pending }} />
                        <div className="app_sidecontent">
                            <Routes appProps={{ onChange_updateStats, onChange_authentication, var_isAuthenticated, var_locales, var_entries, var_duplicates, var_missing, var_pending }} />
                        </div>
                    </div>
                </div>
                :
                <Routes appProps={{ onChange_updateStats, onChange_authentication, var_isAuthenticated, var_locales, var_entries, var_duplicates, var_missing, var_pending }} />
            }



            {/***** MODAL: HISTORY *******************************************************************************/}

            <Modal id="mdl_session_expired"
                dimmer={'inverted'}
                closeOnEscape={false}
                closeOnDimmerClick={false}
                open={var_mdl_session_expired_open}
                onClose={() => handleLogout()}>
                <CMP_SESSION_EXPIRED
                    handleLogout={handleLogout}
                />
            </Modal>

            {/***** END MODAL: DETAILS ***************************************************************************/}

        </>
    );
}

export default App;
