import React from 'react';
import { Link, useLocation } from 'react-router-dom'
import { Flag, Label, Icon, Dimmer, Loader } from 'semantic-ui-react'
import './cmp_sidebar.css'


export default function CMP_SIDEBAR({ appProps }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const location = useLocation();


    //  event listeners ------------------------------------------------------------------------------------------------


    //  async functions ------------------------------------------------------------------------------------------------


    //  API calls ------------------------------------------------------------------------------------------------------


    //  event functions ------------------------------------------------------------------------------------------------


    // RENDER APP ======================================================================================================

    return (
        <>
            <aside className={"app_sidebar" + (appProps.var_sidebar_loading ? " tbl_loading" : "")}>
                {appProps.var_sidebar_loading &&
                    <Dimmer active inverted>
                        <Loader />
                    </Dimmer>
                }
                <div className="app_sidenav_group available_locales">
                    <div className="app_sidenav_group_header">
                        Available Locales
                    </div>
                    {appProps.var_locales.map((item, i) => {
                        return (
                            <div className="sidenav_item" key={i}>
                                <div className="sidenav_item_icon">
                                    <Flag name={item === 'en' ? 'ca' : item} />
                                </div>
                                <div className="sidenav_item_text">
                                    <span>{item}</span>
                                    {item === 'en' && <Label color="grey">Default</Label>}
                                </div>
                            </div>
                        )
                    }
                    )}
                </div>

                <div className="app_sidenav_group locales_stats">
                    <div className="app_sidenav_group_header">
                        Stats
                    </div>
                    <Link className={"sidenav_item" + (location.pathname === "/" ? " active" : '')} to="/">
                        <div className="sidenav_item_icon">
                            <Icon name="arrow alternate circle right outline" />
                        </div>
                        <div className="sidenav_item_text">
                            <span>Entries</span>
                            <span className="entries_count">{appProps.var_entries}</span>
                        </div>
                    </Link>
                    <Link className={"sidenav_item" + (location.pathname === "/missing" ? " active" : '')} to="/missing">
                        <div className="sidenav_item_icon">
                            <Icon name="question circle outline" />
                        </div>
                        <div className="sidenav_item_text">
                            <span>Missing</span>
                            <span className="entries_count">{appProps.var_missing}</span>
                        </div>
                    </Link>
                    <Link className={"sidenav_item" + (location.pathname === "/duplicates" ? " active" : '')} to="/duplicates">
                        <div className="sidenav_item_icon">
                            <Icon name="copy outline" />
                        </div>
                        <div className="sidenav_item_text">
                            <span>Duplicates</span>
                            <span className="entries_count">{appProps.var_duplicates}</span>
                        </div>
                    </Link>
                    <Link className={"sidenav_item" + (location.pathname === "/pending" ? " active" : '')} to="/pending">
                        <div className="sidenav_item_icon">
                            <Icon name="clock outline" />
                        </div>
                        <div className="sidenav_item_text">
                            <span>Pending</span>
                            <span className="entries_count">{appProps.var_pending}</span>
                        </div>
                    </Link>
                </div>

            </aside>
        </>
    )
}
