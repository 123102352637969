
import { Form, TextArea, Button, Input } from 'semantic-ui-react'
import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";


import './mdl_update.css';



export default function UPDATE({ set_mdl_open, var_locales, var_target_key, var_target_id, populate_function, updateStats }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const [var_ready, set_ready] = useState(false);
    const [var_loading, set_loading] = useState(false);
    const [var_error, set_error] = useState(false);
    const [var_message, set_message] = useState(false);
    const [var_isVerified, set_isVerified] = useState([]);
    const [var_translation, set_translation] = useState([]);
    const [var_translation_namespace, set_translation_namespace] = useState('');
    const [var_translation_values, set_translation_values] = useState([]);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        populate_translation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_translation() {
        set_loading(true);
        set_ready(false);

        await API_get_translation(var_target_id);
    }

    async function put_translation() {
        set_loading(true);
        set_ready(false);
        const translation_namespace = (var_translation_namespace !== null && var_translation_namespace !== "" ? var_translation_namespace : "translation");
        set_translation_namespace(translation_namespace);
        const isNamespaceUpdated = (var_translation.namespace !== translation_namespace ? true : false);
        const transactionby = (JSON.parse(sessionStorage.getItem('authentication')).firstname + " " + JSON.parse(sessionStorage.getItem('authentication')).lastname);
        await Promise.all(var_locales.map(async lng => {
            if (var_translation[lng + "_verified"] !== var_isVerified[lng + "_verified"] || var_translation[lng + "_value"] !== var_translation_values[lng + "_value"] || isNamespaceUpdated) {
                await API_put_translation(var_translation[lng + "_id"], translation_namespace, var_isVerified[lng + "_verified"], lng, var_translation_values[lng + "_value"], isNamespaceUpdated, transactionby);
            }
        })).then(() => {
            populate_function();
            updateStats();
            set_mdl_open(false);
        }).catch((e) => {
            set_ready(true);
            set_loading(false);
            set_error(true);
            console.log(e);
        });
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_translation(target_id) {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        return API.get('translation', '/get-translation/' + target_id)
            .then((response) => {
                if (response.status === true) {
                    set_translation(response.translation);
                    let isVerified = { ...var_isVerified };
                    let translations = { ...var_translation_values };
                    var_locales.map(lng => {
                        isVerified[lng + "_verified"] = response.translation[lng + "_verified"];
                        translations[lng + "_value"] = response.translation[lng + "_value"].replace(/\s+/g, ' ');
                        return true;
                    });
                    set_isVerified(isVerified);
                    set_translation_values(translations);
                    set_translation_namespace(response.translation.namespace);
                    set_ready(true);
                    set_loading(false);
                    onChange_setReady(translations);
                } else {
                    set_error(true);
                    set_ready(false);
                    set_loading(false);
                    set_message(response.message.toString());
                    return response.status;
                }
            }).catch((e) => {
                set_ready(false);
                set_loading(false);
                set_error(true);
                console.log(e);
                set_message("Something went wrong.");
            });
    }

    function API_put_translation(translation_id, namespace, isVerified, lng, translation_value, isNamespaceUpdated, transactionby) {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        return API.put('translation', '/put-translation/' + translation_id, { body: { namespace, isVerified, lng, translation_value, isNamespaceUpdated, transactionby } })
            .then((response) => {
                if (response.status === true) {
                    populate_function();
                    updateStats();
                } else {
                    set_error(true);
                    set_ready(true);
                    set_loading(false);
                    set_message(response.message.toString());
                    return response.status;
                }
            });
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_translation_value(id, translation_value) {
        let translations = { ...var_translation_values };
        translations[id] = translation_value.replace(/[\n\r]+/g, "");
        set_translation_values(translations);
        onChange_setReady(translations);
    }

    function onChange_isVerified_value(id) {
        let isVerified = { ...var_isVerified };
        isVerified[id] = isVerified[id] === 1 ? 0 : 1;
        set_isVerified(isVerified);
    }

    function onChange_translation_namespace(namespace) {
        set_translation_namespace(namespace)
    }

    function onClick_submit_update() {
        set_loading(true);
        set_ready(false);
        put_translation();
    }

    function onChange_setReady(translation_values) {
        let hasNull = false;
        if (translation_values === undefined ||
            translation_values === null) {
            hasNull = true;
        } else {
            Object.values(translation_values).map((translation) => {
                if (translation === undefined || translation === null || translation === "") {
                    hasNull = true;
                    return true
                }
                return false
            });
        }
        set_ready(hasNull ? false : true);
    }




    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="modal-header">
                <div className="modal-header-title">Update: {(var_target_key.length > 35) ? var_target_key.substring(0, 35) + "..." : var_target_key}</div>
                <div className="modal-header-close" onClick={() => set_mdl_open(false)}>
                    <img src="/icons/x 60px (717473).svg" alt="Modal close" />
                </div>
            </div>
            <div className="modal-content">
                {var_error &&
                    <div id="loginerror" className="message warning">
                        <div className="message_icon"><img src="/icons/warning 60px (d91e18).svg" alt='warning icon' /></div>
                        <div className="message_text_wrapper">
                            <div className="message_text cause">Failed::{var_message}</div>
                        </div>
                    </div>
                }
                <Form>
                    <Form.Field
                        required
                        key={'0_language_key'}
                        className="language-key"
                        id="language_key"
                        name="language_key"
                        value={var_target_key.replace(/\s+/g, ' ') || ""}
                        control={TextArea}
                        rows={1}
                        label="Key"
                        placeholder="Key..."
                        readOnly={true}
                    />
                    <Form.Field
                        className="namespace"
                        id="namespace"
                        name="namespace"
                        value={var_translation_namespace || ""}
                        control={Input}
                        label="Namespace"
                        placeholder="Name of page..."
                        onChange={(e) => onChange_translation_namespace(e.target.value)}
                    />
                    {var_locales.map((lng, i) =>
                        <div key={i}>
                            <Form.Field
                                key={i + lng + "_value"}
                                required
                                className={"field_language language_" + lng}
                                id={lng + "_value"}
                                name={lng + "_value"}
                                value={var_translation_values[lng + "_value"] || ""}
                                control={TextArea}
                                rows={1}
                                label={(lng === "en" ? "English" : (lng === "fr" ? 'French' : ''))}
                                onChange={(e) => onChange_translation_value(lng + "_value", e.target.value)}
                            />
                            <Form.Checkbox
                                key={i + lng + "_verified"}
                                toggle
                                id={lng + "_verified"}
                                name={lng + "_verified"}
                                label={'Is Verified?'}
                                checked={var_isVerified[lng + "_verified"] === 1 ? true : false}
                                onChange={(e) => onChange_isVerified_value(lng + "_verified")}
                            />
                        </div>
                    )}
                </Form>
            </div>
            <div className="modal-footer">
                <div className="modal-footer-buttons">
                    <Button className="btn_secondary" onClick={() => set_mdl_open(false)}>Cancel</Button>
                    {var_loading ?
                        <Button loading className="btn_primary btn_active">Update</Button>
                        :
                        <Button className={"btn_primary" + (var_ready ? " btn_active" : "")} onClick={() => var_ready ? onClick_submit_update() : false}>Update</Button>
                    }
                </div>
            </div>
        </>
    )
}