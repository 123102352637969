import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { API } from "aws-amplify";
import { Icon, Label, Table, Dimmer, Modal, Loader, Input } from 'semantic-ui-react';
import Moment from "moment";

import CMP_PAGINATION from '../../components/cmp_pagination/cmp_pagination';
import CMP_EDITOR from '../../components/cmp_editor/cmp_editor';
import CMP_HISTORY from '../../components/cmp_history/cmp_history';

import MDL_UPDATE from '../../components/cmp_modals/mdl_update/mdl_update';


export default function Entries(appProps) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const history = useHistory();

    let var_search_timeout = null;

    const [var_translations, set_translations] = useState([]);
    const [var_limit, set_limit] = useState(100);
    const [var_offset, set_offset] = useState(0);
    const [var_ready, set_ready] = useState(false);
    const [var_loading, set_loading] = useState(true);
    const [var_sortby, set_sortby] = useState('en');
    const [var_sortorder, set_sortorder] = useState('ascending');
    const [var_search, set_search] = useState('');
    const [var_search_timer, set_search_timer] = useState(0);
    const [var_search_results, set_search_results] = useState(appProps.var_pending);
    const [var_target_key, set_target_key] = useState('');
    const [var_target_id, set_target_id] = useState('');
    const [var_mdl_update_open, set_mdl_update_open] = useState(false);
    const [var_mdl_history_open, set_mdl_history_open] = useState(false);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (!appProps.var_isAuthenticated) {
            history.push('/login');
        }
    }, [appProps.var_isAuthenticated, history]);

    useEffect(() => {
        set_ready(true);
        set_loading(false);
    }, [var_translations]);

    useEffect(() => {
        if (appProps.var_isAuthenticated) {
            populate_translations();
        }
    }, [appProps.var_isAuthenticated, var_search_timer]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_translations(limit, offset, sortby, sortorder, search) {
        set_loading(true);
        set_ready(false);

        if (limit) {
            set_limit(limit);
        } else {
            limit = var_limit
        }

        if (offset || offset === 0) {
            set_offset(offset);
        } else {
            offset = var_offset
        }

        if (sortby) {
            set_sortby(sortby);
        } else {
            sortby = var_sortby
        }

        if (sortorder) {
            set_sortorder(sortorder);
        } else {
            sortorder = var_sortorder
        }

        if (search) {
            set_search(search);
        } else {
            search = var_search
        }

        await API_get_translations(limit, offset, sortby, sortorder, search);
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_translations(limit, offset, sortby, sortorder, search) {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        return API.get('translation', `/get-pendings-translations?limit=${limit}&offset=${offset}&sortby=${sortby}&sortorder=${sortorder}&search=${search}`)
            .then((translations) => {
                set_translations(translations);
                set_search_results(translations.length > 0 ? (translations[0].totalrows !== undefined ? translations[0].totalrows : 0) : 0);
            });
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_sort(e, sortby) {
        if (appProps.var_pending !== 0) {
            var sortorder = var_sortorder;
            if (e.target === e.currentTarget) {
                if (var_sortby !== sortby) {
                    sortorder = 'ascending';
                } else {
                    if (sortorder === 'ascending') {
                        sortorder = 'descending';
                    } else if (sortorder === 'descending') {
                        sortorder = 'ascending';
                    }
                }
                set_sortorder(sortorder);
                populate_translations('', 0, sortby, sortorder);
            }
        }
    }

    function onClick_show_editor(elem) {
        elem.closest('td').classList.add('editable_form_show');
    }

    function onChange_search(search) {
        set_search(search);
        clearTimeout(var_search_timeout);
        var_search_timeout = setTimeout(() => {
            set_search_timer(var_search_timer + 1);
        }, 500);
    }

    function onOpen_mdl_update(id, translation_key) {
        set_target_key(translation_key);
        set_target_id(id);
        set_mdl_update_open(true);
    }

    function onOpen_mdl_history(en_id, fr_id, translation_key) {
        set_target_key(translation_key);
        set_target_id([en_id,fr_id]);
        set_mdl_history_open(true);
    }


    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="content">
                <div className="content_top_menu">
                    <div className="content_top_menu_icon">
                        <Icon name="arrow alternate circle right outline" />
                    </div>
                    <div className="content_top_menu_title">Pending Translation Entries</div>
                    <Label color="yellow">{appProps.var_pending}</Label>
                </div>

                <div className="content_wrapper">
                    <div className="content_card">
                        <div className="content-card-header">
                            <div className="content-card-header-title">All Pending Entries</div>
                        </div>
                        <div className="content-filter">
                            <div className="content-filter-item item-search">
                                <Input icon='search' placeholder='Search...' value={var_search} onChange={(e) => onChange_search(e.target.value)} />
                            </div>
                            <div className="content-filter-item item-pagination">
                                <div className="pg_group">
                                    <CMP_PAGINATION var_limit={var_limit.toString()} var_ready={var_ready} var_loading={var_loading} tabledata={var_translations} populatefunction={populate_translations}></CMP_PAGINATION>
                                </div>
                            </div>
                        </div>
                        <div className="tablewrapper">
                            {var_loading &&
                                <Dimmer active inverted>
                                    <Loader />
                                </Dimmer>
                            }
                            <Table unstackable sortable compact className={(var_loading ? " tbl_loading" : "")}>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell className="th_actions">STATUS</Table.HeaderCell>
                                        <Table.HeaderCell className="th_keys" sorted={var_sortby === 'key' ? var_sortorder : null} onClick={(e) => onClick_sort(e, 'key')}>KEY</Table.HeaderCell>
                                        <Table.HeaderCell className="th_namespace" sorted={var_sortby === 'namespace' ? var_sortorder : null} onClick={(e) => onClick_sort(e, 'namespace')}>Namespace</Table.HeaderCell>
                                        {appProps.var_locales.map((item, i) =>
                                            <Table.HeaderCell key={i} className="th_language" sorted={var_sortby === item ? var_sortorder : null} onClick={(e) => onClick_sort(e, item)}>
                                                {item}
                                                {item === 'en' &&
                                                    <Label color="grey">Default</Label>
                                                }
                                            </Table.HeaderCell>
                                        )}
                                        <Table.HeaderCell className="th_created_on" sorted={var_sortby === 'created_on' ? var_sortorder : null} onClick={(e) => onClick_sort(e, 'created_on')}>CREATED ON</Table.HeaderCell>
                                        <Table.HeaderCell className="th_history" >HISTORY</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {var_ready && var_translations.length > 0 && var_translations[0].totalrows !== 0 && var_translations.map((item, i) =>
                                        <Table.Row key={i}>
                                            <Table.Cell className="td_actions">
                                                <img src="/icons/warning 30px (ffae00).svg" alt="pending key icon" onClick={() => onOpen_mdl_update(item.en_id, item.trans_key)} />
                                            </Table.Cell>
                                            <Table.Cell className="td_keys" onClick={() => onOpen_mdl_update(item.en_id, item.trans_key)}>
                                                <div className="language_text">
                                                    <div className="editable_text">
                                                        {item.trans_key}
                                                    </div>
                                                </div>
                                            </Table.Cell>
                                            <Table.Cell className="td_namespace" onClick={() => onOpen_mdl_update(item.en_id, item.trans_key)}>
                                                {item.namespace}
                                            </Table.Cell>
                                            {appProps.var_locales.map((lng) =>
                                                <Table.Cell key={i + "_" + lng} className="td_language">
                                                    <div className="language_text" onClick={(e) => onClick_show_editor(e.target)}>
                                                        <div className="editable_text">
                                                            {item[lng + '_value'] ? item[lng + '_value'] : ""}
                                                        </div>
                                                        {(item[lng + '_value'] === "" || item[lng + '_value'] === null) &&
                                                            <Label color="red">Missing</Label>
                                                        }
                                                        {item[lng + '_value'] !== "" && item[lng + '_value'] !== null && item[lng + '_verified'] !== 0 && item[lng + '_duplicate'] &&
                                                            <Label color="teal">Duplicate</Label>
                                                        }
                                                        {item[lng + '_value'] !== "" && item[lng + '_value'] !== null && item[lng + '_verified'] === 0 &&
                                                            <Label color="yellow">Pending</Label>
                                                        }
                                                    </div>
                                                    {appProps.var_isAuthenticated &&
                                                        <CMP_EDITOR var_translation_id={item[lng + '_id']} var_translation={item[lng + '_value']} var_translation_lng={lng} populate_function={populate_translations} updateStats={appProps.onChange_updateStats} />
                                                    }
                                                </Table.Cell>
                                            )}
                                            <Table.Cell className="td_created_on" onClick={() => onOpen_mdl_update(item.en_id, item.trans_key)}>
                                                {(item.created_on !== undefined && item.created_on !== null && item.created_on !== "" ? Moment.unix(item.created_on).utc(false).format('ll') : "n/a")}
                                            </Table.Cell>
                                            <Table.Cell className="td_history" onClick={() => onOpen_mdl_history(item.en_id, item.fr_id, item.trans_key)}>
                                                <img src="/icons/history 30px (bcbebe).svg" alt="" />
                                            </Table.Cell>
                                        </Table.Row>
                                    )}
                                </Table.Body>
                            </Table>
                            {var_ready && var_search_results === 0 &&
                                <div className="emptytable">
                                    <img src="/icons/alert 60px (e5e5e5).svg" alt='' />
                                    there are no pending translation entries to display
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

            {/***** MODAL: TRANSLATION VERIFICATION **************************************************************/}

            <Modal id="mdl_translation_update"
                dimmer={'inverted'}
                open={var_mdl_update_open}
                onClose={() => set_mdl_update_open(false)}
                size="small">
                <MDL_UPDATE
                    set_mdl_open={set_mdl_update_open}
                    var_locales={appProps.var_locales}
                    var_target_key={var_target_key}
                    var_target_id={var_target_id}
                    populate_function={populate_translations}
                    updateStats={appProps.onChange_updateStats}
                />
            </Modal>

            {/***** END MODAL: TRANSLATION VERIFICATION **********************************************************/}



            {/***** MODAL: HISTORY *******************************************************************************/}

            <Modal id="mdl_history"
                dimmer={'inverted'}
                open={var_mdl_history_open}
                onClose={() => set_mdl_history_open(false)}>
                <CMP_HISTORY
                    set_mdl_open={set_mdl_history_open}
                    var_modaltitle={var_target_key}
                    var_target_id={var_target_id}>
                </CMP_HISTORY>
            </Modal>

            {/***** END MODAL: DETAILS ***************************************************************************/}

        </>
    )
}
