import { Button, Form, Pagination } from 'semantic-ui-react'
import React, { useEffect, useState } from "react";


export default function CMP_PAGINATION({var_limit, var_ready, var_loading, tabledata, populatefunction}){

    //  variable declarations ------------------------------------------------------------------------------------------

    const [ var_activepage, set_activepage ] = useState(1);
    const [ var_totalpages, set_totalpages ] = useState(1);
    const [ var_displayrows, set_displayrows ] = useState(var_limit);
    const [ var_displaystart, set_displaystart ] = useState(1);
    const [ var_displayend, set_displayend ] = useState(parseInt(var_limit));



    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        if(tabledata.length>0){
            set_totalpages(Math.ceil(tabledata[0]['totalrows']/var_displayrows));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabledata.length]);



    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_pg_show(event, { value }){
        set_totalpages(Math.ceil(tabledata[0]['totalrows']/value));
        set_displayrows(value);
        set_activepage(1);
        set_displaystart(1);
        if(value > tabledata[0]['totalrows']) {
            set_displayend(tabledata[0]['totalrows']);
        }else{
            set_displayend(value);
        }
        populatefunction(value,0);
    }

    function onChange_page(e, { activePage }){
        set_activepage(activePage);
        set_displaystart(((activePage-1)*var_displayrows)+1);
        if(activePage*var_displayrows > tabledata[0]['totalrows']){
            set_displayend(tabledata[0]['totalrows']);
        }else{
            set_displayend(activePage*var_displayrows);
        }
        populatefunction(var_displayrows,((activePage-1)*var_displayrows));
    }

    function onClick_refresh(){
        populatefunction(var_displayrows,((var_activepage-1)*var_displayrows));
    }



    // RENDER APP ======================================================================================================

    return (
        <div className="pg_group">
            {tabledata.length > 0 && var_limit && var_ready &&
            <>
                {tabledata[0]['totalrows'] > var_displayend ?
                    <div className="pg_results">{'Showing'} <div className="pg_results_emph">{var_displaystart}</div> {'to'} <div className="pg_results_emph">{var_displayend}</div> {'of'} <div className="pg_results_emph">{tabledata[0]['totalrows']}</div> {'Results'}</div>
                    :
                    <div className="pg_results">{'Showing'} <div className="pg_results_emph">{tabledata[0]['totalrows']}</div> {tabledata[0]['totalrows'] > 1 ? 'Results' : 'Result'}</div>
                }

                <Form>
                    <Form.Group>
                        <Form.Select
                            className='pg_show'
                            compact
                            id='pg_show'
                            name='pg_show'
                            placeholder='10'
                            onChange={onChange_pg_show}
                            value={var_displayrows || '10'}
                            options={[{"text": "10", "value": "10"},
                                {"text": "15", "value": "15"},
                                {"text": "25", "value": "25"},
                                {"text": "50", "value": "50"},
                                {"text": "100", "value": "100"}]}
                        />
                        {tabledata[0]['totalrows'] >= var_displayend &&
                            <Pagination
                                activePage={var_activepage}
                                boundaryRange={0}
                                siblingRange={2}
                                size='mini'
                                totalPages={var_totalpages}
                                onPageChange={onChange_page}
                                ellipsisItem={false ? undefined : null}
                                firstItem={true ? undefined : null}
                                lastItem={true ? undefined : null}
                                prevItem={true ? undefined : null}
                                nextItem={true ? undefined : null}
                            />
                        }
                    </Form.Group>
                </Form>
            </>
            }
            <Button id="btn_refresh" className={"btn_icononly pg_refresh" + (var_loading ? " pg_loading" : "")} onClick={() => onClick_refresh()}><img className="btn_icon" src="/icons/refresh 30px (a2a2a1).svg" alt={'refresh icon'}></img></Button>
        </div>
    )
}
