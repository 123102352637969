import React from "react";
import './accessdenied.css';

export default function ACCESSDENIED() {

    //  variable declarations ------------------------------------------------------------------------------------------

    return (
        <div className="AccessDenied">
            <div>
                <h1>Access Denied</h1>
                <h3>You don't have permissions to access this site.</h3>
                <a href="/login">Sign in as different user</a>
            </div>
        </div>
    );
}