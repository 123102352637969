import React, { useState, useEffect } from 'react'
import Moment from "moment";
import './cmp_header.css'

export default function CMP_HEADER({ appProps }) {

    const [var_time, set_time] = useState(Moment().local().format('LT'));
    const [var_date, set_date] = useState(Moment().local().format('ll'));

    useEffect(() => {
        const var_interval = setInterval(() => {
            set_time(Moment().local().format('LT'));
            set_date(Moment().local().format('ll'));
        }, 60000);
        return () => clearInterval(var_interval);
    }, []);

    return (
        <>
            <header className="app_header">
                <div className="app_logo">
                    <img src="https://terrahub-credi-resources.s3.amazonaws.com/public/logo/e2084c80-4147-48a3-8849-f3d36672384f.png" alt="TerraHub" />
                </div>
                <div className="app_header_title">
                    Translation editor
                </div>

                <div className="app-topmenu-UTC">
                    <div className="app-topmenu-UTC-icon"><img src="/icons/clock 60px (8a8d8c).svg" alt='clock icon' /></div>
                    <div className="app-topmenu-UTC-time">{var_time}</div>
                    <div className="app-topmenu-UTC-date">{var_date}</div>
                </div>

                <div className="app-topmenu-loggedin">
                    <div className="app-topmenu-loggedin-icon"><img src="/icons/individual 60px (8a8d8c).svg" alt="individual icon" /></div>
                    <div className="app-topmenu-loggedin-username">
                        {appProps.var_isAuthenticated === true ?
                            JSON.parse(sessionStorage.getItem('authentication')).firstname + JSON.parse(sessionStorage.getItem('authentication')).lastname
                            :
                            "Guest"
                        }
                    </div>
                    {appProps.var_isAuthenticated === true ?
                        <div className="app-topmenu-loggedin-logout" onClick={() => appProps.handleLogout()}>Logout</div>
                        :
                        <div className="app-topmenu-loggedin-logout" onClick={() => appProps.set_mdl_login_open(true)}>Login</div>
                    }
                </div>
            </header>
        </>
    )
}
