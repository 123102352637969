import React, { useState, useEffect } from 'react';
import { API } from "aws-amplify";
import { Form, TextArea, Button, Input } from 'semantic-ui-react'

export default function MDL_ADD({ set_mdl_open, var_locales, populate_function, updateStats }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const [var_ready, set_ready] = useState(false);
    const [var_loading, set_loading] = useState(false);
    const [var_translation_key, set_translation_key] = useState('');
    const [var_translation_namespace, set_translation_namespace] = useState('translation');
    const [var_error, set_error] = useState(false);
    const [var_message, set_message] = useState(false);
    const [var_translation_values, set_translation_values] = useState([]);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        let translations = [];
        var_locales.map(lng => {
            translations[lng + "_value"] = "";
            translations[lng + "_verified"] = 0;
            return true;
        });
        set_translation_values(translations);
    }, [var_locales]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function post_translation() {
        set_error(false);
        set_ready(false);
        set_loading(true);
        let translation_namespace = (var_translation_namespace !== null && var_translation_namespace !== "" ? var_translation_namespace : "translation");
        console.log((var_translation_key, translation_namespace, var_translation_values));
        set_translation_namespace(translation_namespace);
        const transactionby = JSON.parse(sessionStorage.getItem('authentication')).firstname + " " + JSON.parse(sessionStorage.getItem('authentication')).lastname;
        await API_post_translation(var_locales, var_translation_key, translation_namespace, var_translation_values, transactionby);
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_post_translation(locales, translation_key, namespace, translation_value, transactionby) {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        return API.post('translation', '/post-translation', { body: { locales, translation_key, namespace, translation_value, transactionby } }).then((res) => {
            if (res.status === false) {
                set_error(true);
                set_ready(true);
                set_loading(false);
                set_message(res.message.toString());
            } else {
                populate_function();
                updateStats();
                set_mdl_open(false);
            }
        });
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_translation_value(id, translation_value) {
        let translations = { ...var_translation_values };
        translations[id] = translation_value.replace(/[\n\r]+/g, " ");
        set_translation_values(translations);
        if (id.split('_')[0] === "en") {
            set_translation_key(translation_value);
            onChange_setReady(translation_value, translations);
        } else {
            onChange_setReady(var_translation_key, translations);
        }
    }

    function onChange_translation_verified(id, translation_value) {
        let translations = { ...var_translation_values };
        translations[id] = translation_value ? 1 : 0;
        set_translation_values(translations);
        onChange_setReady(var_translation_key, translations);
    }

    function onChange_translation_namespace(namespace) {
        set_translation_namespace(namespace)
    }

    function onChange_setReady(translation_key, translation_values) {
        let hasNull = false;
        if (translation_key === undefined ||
            translation_key === null ||
            translation_key === "" ||
            translation_values === undefined ||
            translation_values === null) {
            hasNull = true;
        } else {
            Object.values(translation_values).map((translation) => {
                if (translation === undefined || translation === null || translation === "") {
                    hasNull = true;
                    return true
                }
                return false
            });
        }
        set_ready(hasNull ? false : true);
    }

    function onClick_submit_translation() {
        set_loading(true);
        set_ready(false);
        post_translation();
    }

    // RENDER APP ======================================================================================================


    return (
        <>
            <div className="modal-header">
                <div className="modal-header-title">Add Translation</div>
                <div className="modal-header-close" onClick={() => set_mdl_open(false)}>
                    <img src="/icons/x 60px (717473).svg" alt="Modal close" />
                </div>
            </div>
            <div className="modal-content">
                {var_error &&
                    <div id="loginerror" className="message warning">
                        <div className="message_icon"><img src="/icons/warning 60px (d91e18).svg" alt='warning icon' /></div>
                        <div className="message_text_wrapper">
                            <div className="message_text cause">Failed::{var_message}</div>
                        </div>
                    </div>
                }
                <Form>
                    <Form.Field
                        required
                        key={'0_language_key'}
                        className="language-key"
                        id="language_key"
                        name="language_key"
                        value={var_translation_key || ""}
                        control={TextArea}
                        rows={1}
                        label="Key"
                        placeholder="Key..."
                        readOnly={true}
                    />
                    <Form.Field
                        className="namespace"
                        id="namespace"
                        name="namespace"
                        value={var_translation_namespace || ""}
                        control={Input}
                        label="Namespace"
                        placeholder="Name of page..."
                        onChange={(e) => onChange_translation_namespace(e.target.value)}
                    />
                    {var_locales.map((lng, i) =>
                        <div key={i}>
                            <Form.Field
                                required
                                className={"field_language language_" + lng}
                                id={lng + "_value"}
                                name={lng + "_value"}
                                value={var_translation_values[lng + "_value"] || ""}
                                control={TextArea}
                                rows={1}
                                label={(lng === "en" ? "English" : (lng === "fr" ? 'French' : ''))}
                                onChange={(e) => onChange_translation_value(lng + "_value", e.target.value)}
                            />
                            <Form.Checkbox
                                toggle
                                id={lng + "_verified"}
                                name={lng + "_verified"}
                                label={'Is Verified?'}
                                value={var_translation_values[lng + "_verified"] || ""}
                                onChange={(e) => onChange_translation_verified(lng + "_verified", e.target.checked)}
                            />
                        </div>
                    )}
                </Form>
            </div>
            <div className="modal-footer">
                <div className="modal-footer-buttons">
                    <Button className="btn_secondary" onClick={() => set_mdl_open(false)}>Cancel</Button>
                    {var_loading ?
                        <Button loading className="btn_primary btn_active">Save</Button>
                        :
                        <Button className={"btn_primary" + (var_ready ? " btn_active" : "")} onClick={() => var_ready ? onClick_submit_translation() : false}>Save</Button>
                    }
                </div>
            </div>
        </>
    )
}
